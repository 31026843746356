import axiosInstance from "../utils/axios";
import Cookies from "js-cookie";

export const getCampaignDetails = async (id) => {
    try {
        const res = await axiosInstance.get(`api/jobs/campaign/${id}`);
        return res;
    } catch (err) {
        if(err.response.status === 401) {
            Cookies.remove("authToken");
        }
        throw Error(err);
    }
}

export const getCampaignInviteToken = async (id) => { 
    try {
        const res = await axiosInstance.get(`api/candidates/campaign/invite-url?campaignId=${id}`);
        return res;
    } catch (err) {
        return {error: err?.response?.data?.msg, status: err?.response?.status};
    }
}