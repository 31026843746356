
export const PROGRESS_STEPS = {
    UserConfirm: {
        slug: 'UserConfirm',
        order: 1
    },
    Introduction: {
        slug: 'Introduction',
        order: 2
    },
    CameraMicSetup: {
        slug: 'CameraMicSetup',
        order: 3
    },
    GuidelineAssessment: {
        slug: 'GuidelineAssessment',
        order: 4
    },
    TestWindow: {
        slug: 'TestWindow',
        order: 5
    },
    ActualTestStart: {
        slug: 'ActualTestStart',
        order: 6
    },
    TestQuestion: {
        slug: 'TestQuestion',
        order: 7
    },
    Submitted: {
        slug: 'Submitted',
        order: 8
    },
}

export const COOKIE_NAMES = {
    ACCESS_TOKEN: '_at',
    TIME_TOTAL: '_tt',
    TIME_LEFT: '_tl',
}

export const LOCAL_CAMPAIGN_STATUS = {
    DUPLICATE_CAMPAIGN: "DUPLICATE_CAMPAIGN",
    INVALID: "INVALID",
    EXPIRED: "EXPIRED",
    USAGE_LIMIT_EXCEEDED: "USAGE_LIMIT_EXCEEDED",
    NOT_STARTED: "NOT_STARTED",
    VALID: "VALID",
    PAUSED: "PAUSED",
}
